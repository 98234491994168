import React from 'react';
import { Card, Col, Row, Typography, Image, Space, Tag } from 'antd';
import picksData from '../data/picksData';
import '../styles/Homepage.css';

const { Title, Text } = Typography;

const Outcome = ({ isWin, outcome }) => {
  if (outcome) {
    return (
      <div>
        <Text strong>Outcome: </Text>
        <Tag color={isWin ? 'green' : 'red'}>{isWin ? 'Win' : 'Lose'}</Tag>
      </div>
    );
  }

  return null;
};

const Homepage = () => {
  return (
    <div className="homepage" style={{ padding: '20px' }}>
      <Title level={2}>Premium Picks</Title>

      <Row gutter={[16, 16]}>
        {picksData.map(pick => {
          const { id, dateTime, title, teams, venue, outcome, isWin, description } = pick;

          return (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} key={id}>
              <Card bordered={false} hoverable className="premium-pick-card">
                <Space direction="vertical" size={12} className="card-content">
                  <div className="date-time-badge">
                    <Text strong>{dateTime}</Text>
                  </div>

                  <Title level={4}>{title}</Title>

                  <Space align="center" className="team-logos">
                    <Image width={30} src={teams[0].logo} alt={teams[0].name} />
                    <Text strong>vs</Text>
                    <Image width={30} src={teams[1].logo} alt={teams[1].name} />
                  </Space>

                  <div>
                    <Text strong>Matchup: </Text>{teams.map(team => team.name).join(' vs ')}<br />
                    <Text strong>Venue: </Text>{venue}<br />
                    <Outcome isWin={isWin} outcome={outcome} />
                    {!outcome && <div><Text strong>Prediction: </Text>{description}</div>}
                  </div>
                </Space>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Homepage;
