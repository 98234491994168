import React from 'react';

const AdminPage = () => (
  <div>
    <h1>Admin Page</h1>
    <p>This is the admin page. Add functionalities to manage picks here.</p>
  </div>
);

export default AdminPage;
