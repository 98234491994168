import React from 'react';
import { Card, Col, Row } from 'antd';

const picks = [
  // Sample data
  { id: 1, title: 'Pick 1', description: 'This is pick 1' },
  { id: 2, title: 'Pick 2', description: 'This is pick 2' },
  // Add more picks as needed
];

const PicksPage = () => (
  <div>
    <h1>Picks</h1>
    <Row gutter={16}>
      {picks.map(pick => (
        <Col span={8} key={pick.id}>
          <Card title={pick.title} bordered={false}>
            {pick.description}
          </Card>
        </Col>
      ))}
    </Row>
  </div>
);

export default PicksPage;
