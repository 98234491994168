import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import PicksPage from './pages/PicksPage';
import AdminPage from './pages/AdminPage';
import NotFoundPage from './pages/NotFoundPage';
import Navbar from './components/Navbar';
// import { ConfigProvider, theme } from 'antd';

// const { darkAlgorithm } = theme;

const App = () => {
  return (
    // <ConfigProvider
    //   theme={{
    //     token: {
    //       colorPrimary: "#464646",
    //       colorInfo: "#464646",
    //       wireframe: false
    //     },
    //     algorithm: [darkAlgorithm],
    //   }}
    // >
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/picks" element={<PicksPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    // </ConfigProvider>
  );
};

export default App;
